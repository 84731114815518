import React, {useMemo} from "react";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";
import {listPaymentMeans} from "../utils/payment";

export function useGameBuyBookPayments(book: any, game: any, { t, workflows }: { t: Function; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game_buy_book', book, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'bookpayment', 'payment', {game, book});
    let paymentMeans = listPaymentMeans('bookpayment', (feature: string, mean: any) => {
        if (!game.features || !game.features.includes(feature) || (!!game.features.includes(`hide_${feature}`) && query.get('p') !== feature)) return null;
        if (!mean) return null;
        return {
            id: mean.id,
            ...(mean.label ? {label: t(mean.label)} : {}),
            ...(mean.helper ? {helper: t(mean.helper)} : {}),
            ...(mean.ifAlone ? {ifAlone: t(mean.ifAlone)} : {}),
            ...(mean.ifNotAlone ? {ifNotAlone: t(mean.ifNotAlone)} : {}),
        };
    });
    const isPromiseOnly = (paymentMeans?.length === 1) && (((paymentMeans || [{}])[0] || {}).id === 'promise');
    const items = useMemo(() => (paymentMeans || []).map((paymentMean: any, index: number) => {
        query.set('p', paymentMean.id);
        const alone = paymentMeans?.length === 1;
        return {
            id: paymentMean.code || index,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            value: paymentMean.value,
            helper: <>
                {((!alone && !paymentMean.ifNotAlone) || (alone && !paymentMean.ifAlone)) && !!paymentMean.helper && (
                    <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.helper}</div>
                )}
                {alone && !!paymentMean.ifAlone && (
                    <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.ifAlone}</div>
                )}
                {!alone && !!paymentMean.ifNotAlone && (
                  <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.ifNotAlone}</div>
                )}
            </>,
            label: paymentMean.label,
        };
    }), [paymentMeans, query, game, urlBuilder]);

    return useMemo(() => [items, { isPromiseOnly }], [items, isPromiseOnly]) as [any[], { isPromiseOnly: boolean }];
}

export default useGameBuyBookPayments;
