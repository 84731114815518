import React, {useMemo} from "react";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import {listPaymentMeans} from "../utils/payment";

export function useGamePromisePayments(promise: any, game: any, { t }: { t: Function }) {
    const query = useQueryString();
    const paymentMeans = listPaymentMeans('promise', (feature: string, mean: any) => {
        if (!game.features || !game.features.includes(feature)) return null;
        if (!mean) return null;
        return {
            id: mean.id,
            ...(mean.label ? {label: t(mean.label)} : {}),
            ...(mean.helper ? {helper: t(mean.helper)} : {}),
            ...(mean.ifAlone ? {ifAlone: t(mean.ifAlone)} : {}),
            ...(mean.ifNotAlone ? {ifNotAlone: t(mean.ifNotAlone)} : {}),
        };
    });
    return useMemo(() => (paymentMeans || []).map((paymentMean: any, index: number) => {
        query.set('p', paymentMean.id);
        const alone = paymentMeans.length === 1;
        return {
            id: paymentMean.id || index,
            target: `${process.env.RAZZLE_GOTOMBOLA_API_PAYMENT_ENDPOINT}/payments/complete/game-promises/${promise.id}?${query.toString()}`,
            helper: <>
                {((!alone && !paymentMean.ifNotAlone) || (alone && !paymentMean.ifAlone)) && !!paymentMean.helper && (
                    <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.helper}</div>
                )}
                {alone && !!paymentMean.ifAlone && (
                    <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.ifAlone}</div>
                )}
                {!alone && !!paymentMean.ifNotAlone && (
                  <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.ifNotAlone}</div>
                )}
            </>,
            label: paymentMean.label,
        }
    }), [paymentMeans, query, promise]);
}

export default useGamePromisePayments;
