import React, {useCallback, useMemo} from "react";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";
import {listPaymentMeans} from "../utils/payment";

export function useGameBuyPayments(game: any, { t, workflows}: { t: Function; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'payment', {game});
    let paymentMeans = listPaymentMeans('sale', (feature: string, mean: any) => {
        if (!game.features || !game.features.includes(feature) || (!!game.features.includes(`hide_${feature}`) && query.get('p') !== feature)) return null;
        if (!mean) return null;
        return {
            id: mean.id,
            ...(mean.label ? {label: t(mean.label)} : {}),
            ...(mean.helper ? {helper: t(mean.helper)} : {}),
            ...(mean.ifAlone ? {ifAlone: t(mean.ifAlone)} : {}),
            ...(mean.ifNotAlone ? {ifNotAlone: t(mean.ifNotAlone)} : {}),
        };
    });

    let blockMode = false;

    // if only promise BUT free mode enabled, do not display promise and go to next screen
    if (paymentMeans.length === 1 && paymentMeans?.[0]?.id === 'promise' && game?.features?.includes('free')) {
        paymentMeans = [];
    }
    // if only preorder BUT free mode enabled, do not display preorder and go to next screen
    if (paymentMeans.length === 1 && paymentMeans?.[0]?.id === 'preorder' && game?.features?.includes('free')) {
        paymentMeans = [];
    }

    const isPromiseOnly = (paymentMeans.length === 1) && (((paymentMeans || [{}])[0] || {}).id === 'promise');
    const isPreorderOnly = (paymentMeans.length === 1) && (((paymentMeans || [{}])[0] || {}).id === 'preorder');
    const items = useMemo(() => (paymentMeans || []).map((paymentMean: any, index: number) => {
        query.set('p', paymentMean.id);
        const alone = paymentMeans.length === 1;
        return {
            id: paymentMean.code || index,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            value: paymentMean.value,
            helper: <>
                {((!alone && !paymentMean.ifNotAlone) || (alone && !paymentMean.ifAlone)) && !!paymentMean.helper && (
                <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.helper}</div>
            )}
        {alone && !!paymentMean.ifAlone && (
            <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.ifAlone}</div>
        )}
        {!alone && !!paymentMean.ifNotAlone && (
            <div className={'font-go-text text-go-text font-light pt-4'}>{paymentMean.ifNotAlone}</div>
        )}
        </>,
        label: paymentMean.label,
    };
    }), [paymentMeans, query, game, urlBuilder]);

    const buildNextStepUrl = useCallback(() => {
        return buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder)
    }, [game, nextStep, query, urlBuilder]);

    // if no payment means but ticket price is not free, display an error message
    if (items.length === 0 && game.ticketPriceAmount > 0) {
        blockMode = true;
    }

    return useMemo(() => [items, { buildNextStepUrl, blockMode, isPromiseOnly, isPreorderOnly }], [items, buildNextStepUrl, blockMode, isPromiseOnly, isPreorderOnly]) as [any[], { buildNextStepUrl: Function; blockMode: boolean; isPromiseOnly: boolean; isPreorderOnly: boolean }];
}

export default useGameBuyPayments;
